<template>
  <section>
    <b-card-body id="address">
      <div class="d-flex justify-content-between mb-1">
        <b-card-title class="mt-1  align-items-center">
          {{ $t('address') }}
        </b-card-title>
        <b-button
          variant="primary"
          class="d-flex cursor-pointer align-items-center"
          style="height: min-content;"
          @click="newAddress()"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
            size="20"
          />
          {{ $t('Add') }}
        </b-button>
      </div>
      <b-list-group class="list-group-messages">
        <b-list-group-item
          v-for="(addressObj, indexObj) in addresList"
          :key="'card_' + indexObj"
          class="d-flex justify-content-between"
        >
          <div class="align-items-center">
            <feather-icon
              :icon="addressObj.node.type=='SHI'?'TruckIcon':'FileTextIcon'"
              :class="addressObj.node.isActive?'mr-1  text-success  -sm':'mr-1  text-danger -sm'"
            />
            <span>{{ addressObj.node.addressName }} </span>
            <span>{{ addressObj.node.address }} </span>
          </div>
          <div>
            <feather-icon
              icon="EditIcon"
              size="17"
              class="text-warning mr-2 cursor-pointer"
              @click="editAddress(indexObj)"
            />
            <feather-icon
              icon="XCircleIcon"
              size="17"
              class="text-danger cursor-pointer"
              @click="deleteAddress(indexObj,addressObj.node.id)"
            />
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
    <b-modal
      ref="modal"
      size="xl"
      hide-footer
      title="Direcciones"
    >
      <div class="d-block text-center">
        <b-form @submit="saveData($event)">
          <b-row
            cols="6"
            no-gutters
            class="address-box "
          >
            <b-col class="m-1">
              <b-form-group :label="$t('nameDir')">
                <b-form-input
                  v-model="addressName"
                  required
                  :placeholder="$t('nameDir')"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>
            <b-col class="m-1">
              <b-form-group :label="$t('administrators.user')">
                <b-form-input
                  v-model="name"
                  required
                  :placeholder="$t('administrators.user')"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>
            <b-col class="m-1">
              <b-form-group :label="$t('lastName')">
                <b-form-input
                  v-model="lastName"
                  required
                  :placeholder="$t('lastName')"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>
            <b-col class="m-1">
              <b-form-group :label="$t('typeDir')">
                <b-form-select
                  v-model="type"
                  :options="[
                    {value:'SHI',text:$t('Entrega')},
                    {value:'BIL',text:$t('Facturación')},
                  ]"
                  class="mb-3"
                />
              </b-form-group>
            </b-col>
            <b-col class="m-1">
              <b-form-group :label="$t('finalUsers.Province')">
                <b-form-input
                  v-model="province"
                  required
                  :placeholder="$t('finalUsers.Province')"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>

            <b-col class="m-1">
              <b-form-group :label="$t('localidad')">
                <b-form-input
                  v-model="city"
                  required
                  :placeholder="$t('localidad')"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>
            <b-col class="m-1">
              <b-form-group :label="$t('finalUsers.Address')">
                <b-form-input
                  v-model="address"
                  required
                  :placeholder="$t('finalUsers.Address')"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>

            <b-col class="m-1">
              <b-form-group :label="$t('finalUsers.Code')">
                <b-form-input
                  v-model="postalCode"
                  required
                  :placeholder="$t('finalUsers.Code')"
                  maxlength="5"
                  type="text"
                  @input="validationPostal(postalCode)"
                />
                <small class="text-danger ">{{ msgPostal }}</small>
              </b-form-group>
            </b-col>

            <b-col class="m-1">
              <b-form-group :label="$t('finalUsers.Country')">
                <b-form-select
                  v-model="country"
                  class="mb-3"
                >
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    {{ $t('selectCountry') }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="coun in allCountry"
                    :key="coun.node.id"
                    :value="coun.node.id"
                  >
                    {{ coun.node.country }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col
              v-if="type=='SHI'"
              class="m-1"
            >
              <b-form-group label="NIF/ CIF/ NIE">
                <b-form-input
                  v-model="nif"
                  required
                  placeholder="nif/ cif/ nie"
                  type="text"
                  maxlength="9"
                  @input="validationDni(nif)"
                />
                <small class="text-danger ">{{ msgDni }}</small>
              </b-form-group>
            </b-col>

            <b-col class="m-1">
              <b-form-group :label="$t('finalUsers.Phone')">
                <b-form-input
                  v-model="phone"
                  required
                  :placeholder="$t('finalUsers.Phone')"
                  type="text"
                  maxlength="9"
                  @input="validationPhone(phone)"
                />
                <small class="text-danger ">{{ msgPhone }}</small>
              </b-form-group>
            </b-col>
            <b-col class="m-1">
              <b-form-group :label="$t('dataGeneric.active')">
                <b-form-checkbox
                  v-model="isActive"
                  switch
                >
                  {{ $t('dataGeneric.activeQ') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="mt-2">
              <b-button
                type="submit"
                variant="success"
                :disabled="!boolPostal && !boolPhone && !nifValidation ? false : true "
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BListGroup,
  BListGroupItem,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive' // Notification
import axios from '@axios'
import {
  messageError, isEmpty, identityDocument, postal, phoneNumber, showToast,
} from '@/store/functions'

export default {
  components: {
    BCardTitle,
    BCardBody,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BModal,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    objeto: {
      type: Array,
      dedault: [],
    },
    user: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      address: null,
      addressName: null,
      city: null,
      country: null,
      allCountry: [],
      id: null,
      isActive: true,
      lastName: null,
      name: null,
      index: 0,
      nif: null,
      phone: null,
      postalCode: null,
      province: null,
      type: 'SHI',
      create: false,
      nifValidation: false,
      boolPhone: false,
      boolPostal: false,
      msgPostal: '',
      msgPhone: '',
      msgDni: '',
      addresList: [],
    }
  },
  watch: {
    objeto() {
      this.addresList = this.objeto
    },
  },

  mounted() {
    this.addresList = this.objeto
    this.countryList()
  },

  methods: {
    validationDni(dni) {
      identityDocument(dni).then(result => {
        this.nifValidation = result
        this.msgDni = ''
      }).catch(err => {
        this.nifValidation = true
        this.msgDni = err
      })
    },
    validationPostal(post) {
      postal(post).then(result => {
        this.boolPostal = result
        this.msgPostal = ''
      }).catch(err => {
        this.boolPostal = true
        this.msgPostal = err
      })
    },
    validationPhone(phone) {
      phoneNumber(phone).then(result => {
        this.boolPhone = result
        this.msgPhone = ''
      }).catch(err => {
        this.boolPhone = true
        this.msgPhone = err
      })
    },

    countryList() {
      const query = `
                {
                    allCountry {
                    edges {
                        node {
                        id
                        country
                        }
                    }
                }
            }  
            `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allCountry = result.data.data.allCountry.edges
        })
        .catch(err => { console.log(err) })
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    newAddress() {
      this.address = null
      this.addressName = null
      this.city = null
      this.country = null
      this.id = null
      this.isActive = true
      this.lastName = null
      this.name = null
      this.nif = null
      this.phone = null
      this.postalCode = null
      this.province = null
      this.type = 'SHI'
      this.create = true
      this.$refs.modal.show()
    },
    editAddress(i) {
      this.address = this.addresList[i].node.address
      this.addressName = this.addresList[i].node.addressName
      this.city = this.addresList[i].node.city
      this.country = this.addresList[i].node.country != null ? this.addresList[i].node.country.id : null
      this.id = this.addresList[i].node.id
      this.isActive = this.addresList[i].node.isActive
      this.lastName = this.addresList[i].node.lastName
      this.name = this.addresList[i].node.name
      this.nif = this.addresList[i].node.nif
      this.phone = this.addresList[i].node.phone
      this.postalCode = this.addresList[i].node.postalCode
      this.province = this.addresList[i].node.province
      this.type = this.addresList[i].node.type
      this.create = false
      this.index = i
      this.$refs.modal.show()
    },
    saveData(event) {
      event.preventDefault()
      if (this.create) {
        axios
          .post('', {
            query: `
                mutation{
                    createAddress(input:{
                      addressName:"${this.addressName}",
                      user:"${this.user}",
                      address:"${this.address}",
                      name:"${this.name}",
                      lastName:"${this.lastName}",
                      city:"${this.city}",
                      ${isEmpty(this.country) ? '' : `country:"${this.country}",`}
                      
                      isActive:${this.isActive},
                      nif:"${this.nif}",
                      phone:"${this.phone}",
                      postalCode:"${this.postalCode}",
                      province:"${this.province}",
                      type:${this.type},
                    }){
                      address{
                        id
                      }
                    }
                  }
            `,
          })
          .then(result => {
            messageError(result, this)

            const newId = result.data.data.createAddress.address.id
            showToast(this.$t('success'), 1, this)
            this.addresList.push({
              node: {
                address: this.address,
                addressName: this.addressName,
                city: this.city,
                country: { id: this.country },
                id: newId,
                isActive: this.isActive,
                lastName: this.lastName,
                name: this.name,
                nif: this.nif,
                phone: this.phone,
                postalCode: this.postalCode,
                province: this.province,
                type: this.type,
              },
            })
            this.hideModal()
          })
          .catch(err => {
            console.log(err)

            showToast(this.$t('error'), 2, this)
          })
      } else {
        axios
          .post('', {
            query: `
                mutation{
                    updateAddress(id:"${this.id}",input:{
                      addressName:"${this.addressName}",
                      user:"${this.user}",
                      address:"${this.address}",
                      name:"${this.name}",
                      lastName:"${this.lastName}",
                      city:"${this.city}",
                      country:"${this.country}",
                      isActive:${this.isActive},
                      nif:"${this.nif}",
                      phone:"${this.phone}",
                      postalCode:"${this.postalCode}",
                      province:"${this.province}",
                      type:${this.type},
                    }){
                      address{
                        id
                      }
                    }
                  }
            `,
          })
          .then(result => {
            messageError(result, this)

            showToast(this.$t('success'), 1, this)
            this.addresList[this.index].node = {
              address: this.address,
              addressName: this.addressName,
              city: this.city,
              country: { id: this.country },
              id: this.id,
              isActive: this.isActive,
              lastName: this.lastName,
              name: this.name,
              nif: this.nif,
              phone: this.phone,
              postalCode: this.postalCode,
              province: this.province,
              type: this.type,
            }
            this.hideModal()
          })
          .catch(err => {
            console.log(err)
            showToast(this.$t('error'), 2, this)
          })
      }
    },
    deleteAddress(index, id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
               mutation{
                 deleteAddress(id:"${id}") {
                   found
                   deletedId
                 }
                  }
            `,
            })
            .then(res => {
              messageError(res, this)

              showToast(this.$t('success'), 1, this)

              this.addresList.splice(index, 1)
              this.hideModal()
            })
            .catch(err => {
              console.log(err)

              showToast(this.$t('error'), 2, this)
            })
        }
      }).catch(() => {

      })
    },
  },

}
</script>
<style>
.address-box {
  /* height: 70px; */
  padding: 15px;
  border: 1px solid white
}

</style>

<template>
  <section id="final-users">
    <b-row>
      <b-col>
        <h1>{{ $t("finalUsers.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("finalUsers.edit") }}
        </h5>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end m-1">
          <router-link to="/users/final-users">
            <b-button variant="danger">
              {{ $t("dataGeneric.goBack") }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col />
    </b-row>
    <b-card class="mt-5">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-tabs
            v-if="introspectionData.username && userData"
            v-model="tabs"
          >
            <b-tab active>
              <template #title>
                <feather-icon icon="UserIcon" />
                <span>{{ $t("User") }}</span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.usIn") }}
                </b-card-title>
                <b-row>
                  <b-col md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="User Name"
                      rules="required"
                    >
                      <b-form-group
                        v-b-tooltip.hover.bottom="introspectionData.username.description
                        "
                        :label="$t('administrators.user')"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="mc-user-name"
                          :value="cleanUsername(userData.username)"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('administrators.user')"
                          maxlength="75"
                          disabled
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.firstName.description
                      "
                      :label="$t('administrators.name')"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="userData.firstName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.name')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastName.description
                      "
                      :label="$t('administrators.lname')"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="userData.lastName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.lname')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.nif.description
                      "
                      label="NIF/ CIF/ NIE"
                    >
                      <b-form-input
                        id="mc-user-nif"
                        v-model="userData.nif"
                        autocomplete="new-password"
                        placeholder="nif/ cif/ nie"
                        maxlength="9"
                        @input="validationDni(userData.nif)"
                      />
                      <small class="text-danger">{{ msgDni }}</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="7">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.description.description
                      "
                      :label="$t('finalUsers.usdes')"
                    >
                      <b-form-input
                        id="mc-user-description"
                        v-model="userData.description"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.usdes')"
                        maxlength="250"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.gender.description
                      "
                      :label="$t('finalUsers.usgen')"
                    >
                      <v-select
                        v-model="userData.gender"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="selectOptions(
                          introspectionData.gender.type.enumValues
                        )
                        "
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="mc-user-gender"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="subgroups"
                    md="4"
                  >
                    <b-form-group
                      v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                      :label="$t('Subgroup')"
                    >
                      <v-select
                        id="subgroup"
                        v-model="userData.subgroup"
                        :options="subgroups"
                        label="name"
                        :placeholder="$t('SinGrupoUsuario')"
                        aria-autocomplete="new-password"
                        autocomplete="new-password"
                        :reduce="option => option.id"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AtSignIcon" />
                <span>{{ $t("administrators.contact") }}</span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.contact2") }}
                </b-card-title>
                <b-row>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="User Name"
                      rules="required"
                    >
                      <b-form-group
                        v-b-tooltip.hover.bottom="introspectionData.email.description
                        "
                        label="Email"
                        :invalid-feedback="$t('required')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <b-form-input
                          id="mc-user-email"
                          v-model="userData.email"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          placeholder="e-mail"
                          maxlength="75"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.phone.description"
                      :label="$t('finalUsers.Phone')"
                    >
                      <b-form-input
                        id="mc-user-phone"
                        v-model="userData.phone"
                        type="text"
                        maxlength="9"
                        :placeholder="$t('finalUsers.Phone')"
                        @input="validationPhone(userData.phone)"
                      />
                      <small class="text-danger">{{ msgPhone }}</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="5">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.address.description
                      "
                      :label="$t('finalUsers.Address')"
                    >
                      <b-form-input
                        id="mc-user-address"
                        v-model="userData.address"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.Address')"
                        maxlength="200"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.postalCode.description"
                      :label="$t('finalUsers.Code')"
                    >
                      <b-form-input
                        id="mc-postalCode-name"
                        v-model="userData.postalCode"
                        type="text"
                        maxlength="5"
                        :placeholder="$t('finalUsers.Code')"
                        @input="validationPostal(userData.postalCode)"
                      />
                      <small class="text-danger">{{ msgPostal }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.city.description
                      "
                      :label="$t('finalUsers.City')"
                    >
                      <b-form-input
                        id="mc-city-name"
                        v-model="userData.city"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.City')"
                        maxlength="200"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.province.description
                      "
                      :label="$t('finalUsers.Province')"
                    >
                      <b-form-input
                        id="mc-province-name"
                        v-model="userData.province"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.Province')"
                        maxlength="200"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.country.description
                      "
                      :label="$t('finalUsers.Country')"
                    >
                      <b-form-input
                        id="mc-country-name"
                        v-model="userData.country"
                        autocomplete="new-password"
                        :placeholder="$t('finalUsers.Country')"
                        maxlength="250"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SettingsIcon" />
                <span>{{ $t("administrators.info") }}</span>
              </template>
              <b-card-body>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.dateJoined.description
                      "
                      :label="$t('administrators.date')"
                    >
                      <b-form-datepicker
                        disabled
                        :value="userData.dateJoined"
                        :readonly="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastLogin.description
                      "
                      :label="$t('administrators.last')"
                    >
                      <b-form-datepicker
                        disabled
                        :value="userData.lastLogin"
                        :readonly="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.pin.description
                      "
                      :label="$t('pin')"
                    >
                      <b-form-input
                        id="mc-pin-name"
                        v-model="userData.pin"
                        autocomplete="new-password"
                        :placeholder="$t('pin')"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.isActive"
                        v-b-tooltip.hover.bottom="introspectionData.isActive.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t("administrators.usac") }}
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.confirmedEmail"
                        v-b-tooltip.hover.bottom="introspectionData.confirmedEmail.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('emailCheck') }}
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.isLegalWarning"
                        v-b-tooltip.hover.bottom="introspectionData.isLegalWarning.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('acept') }} <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="2"
                    style="align-self: center;"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="userData.isAcceptAds"
                        v-b-tooltip.hover.bottom="introspectionData.isAcceptAds.description
                        "
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ $t('promoRecep') }} <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row />
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="BookOpenIcon" />
                <span>{{ $t('address') }} </span>
              </template>
              <address-component
                :objeto="userData.userAddress == null ? [] : userData.userAddress.edges"
                :user="userData.id"
              />
            </b-tab>
          </b-tabs>
        </b-form>
      </validation-observer>
      <b-button
        v-show="tabs == 2"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="mutateUser()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>
      <b-button
        v-show="tabs == 0"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="nifValidation"
        @click="mutateUser()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>

      <b-button
        v-show="tabs == 1"
        :disabled="!boolPostal && !boolPhone ? false : true"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="mutateUser()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>
    </b-card>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import {
  identityDocument, postal, phoneNumber, cleanUsername, getSubgroups, checkPermissions,
} from '@/store/functions'

import {
  BCard,
  BTabs,
  BTab,
  BCol,
  BRow,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BFormCheckbox,
  BFormDatepicker,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, length } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive' // Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import selectOptions from '@/libs/utils/v-select-from-reflection'
import i18n from '@/libs/i18n'
import { getUserData } from '@/auth/utils'
import userStoreModule from './userStoreModule'
import addressComponent from './address.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    BTab,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    addressComponent,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref(null)
    const introspectionData = ref({})

    try {
      store
        .dispatch('final-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          userData.value = response.data.data.allVusers.edges[0].node
          userData.value.postalCode = null
          const tempObject = {}
          /* eslint-disable no-unused-vars */
          Object.entries(response.data.data.allVusers.edges[0].node).forEach(
            ([key, value2]) => {
              /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
              response.data.data.__type.fields.forEach(value => {
                if (key === value.name) {
                  const mvalue = value
                  mvalue.value = value2
                  tempObject[key] = mvalue
                }
              })
            },
          )
          introspectionData.value = tempObject
        })
        .catch(error => {
          console.log(error)
          userData.value = undefined
        })
    // eslint-disable-next-line no-empty
    } catch (error) {

    }
    const mutateUser = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          store
            .dispatch('final-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
            })
            .then(response => {
              if (response.data.data.updateVuser) {
                userData.value = response.data.data.updateVuser.vUser
                userData.value.postalCode = null
                Object.entries(response.data.data.updateVuser.vUser).forEach(
                  ([key, value2]) => {
                    if (key !== 'id') introspectionData.value[key].value = value2
                  },
                )
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('error'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            return key
          })
        }
      })
    }

    return {
      userData,
      introspectionData,
      // update,
      selectOptions,
      mutateUser,
      simpleRules,
    }
  },
  data() {
    return {
      email,
      required,
      length,
      tabs: 0,
      msgDni: '',
      msgPostal: '',
      msgPhone: '',
      nifValidation: false,
      boolPostal: false,
      boolPhone: false,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },
  beforeDestroy() {
    if (store.hasModule('final-user')) store.unregisterModule('final-user')
  },
  beforeCreate() {
    if (!store.hasModule('final-user')) store.registerModule('final-user', userStoreModule)
  },
  mounted() {
    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
    }
  },
  methods: {
    validationDni(dni) {
      identityDocument(dni).then(result => {
        this.nifValidation = result
        this.msgDni = ''
      }).catch(err => {
        this.nifValidation = true
        this.msgDni = err
      })
    },

    validationPostal(post) {
      postal(post).then(result => {
        this.boolPostal = result
        this.msgPostal = ''
      }).catch(err => {
        this.boolPostal = true
        this.msgPostal = err
      })
    },

    validationPhone(phone) {
      phoneNumber(phone).then(result => {
        this.boolPhone = result
        this.msgPhone = ''
      }).catch(err => {
        this.boolPhone = true
        this.msgPhone = err
      })
    },
    cleanUsername,
  },
}
</script>
<style>
.address-box {
  /* height: 70px; */
  padding: 15px;
  opacity: 0.8;
  border: 1px solid white
}
</style>
